<template>
  <v-card :flat="references.cardFlat !== undefined ? references.cardFlat : false">
    <v-form ref="validateForm" autocomplete="off">
      <v-card-title primary-title :class="references.cardTitleClass !== undefined ? references.cardTitleClass : 'px-2 py-1'" v-if="references.title">
        <h5> {{ references.title }} </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider v-if="references.title"></v-divider>
      <v-container fluid grid-list-xl>
        <v-layout wrap :class="references.layoutClass !== undefined ? references.layoutClass : 'px-0 py-1'">
          <template v-for="(prop, index) in references.properties">
            <v-flex v-if="prop.is_show !== undefined ? prop.is_show : true" :class="prop.class" :key="index" :style="prop.style" style="padding:5px !important;">
              <template v-if="prop.type === formType.TEXT">
                <v-text-field outlined :maxlength="prop.max ? prop.max : ''"  :hide-details="prop.hideDetails !== undefined ? prop.hideDetails : true" :label="prop.label" :suffix="prop.suffix" :id="prop.model" :ref="prop.ref" @input="prop.input" :disabled="prop.disabled" :rules="prop.rules" v-model="model[prop.model]" dense @change="prop.change ? prop.change() : ''"></v-text-field>
              </template>
              <template v-if="prop.type === formType.NUMBER">
                <v-text-field outlined hide-details :type="formType.NUMBER" :id="prop.model" :label="prop.label" :rules="prop.rules" v-model="model[prop.model]" :disabled="prop.disabled" dense> </v-text-field>
              </template>
              <template v-else-if="prop.type === formType.TEXTAREA">
                <v-textarea outlined hide-details :label="prop.label" :id="prop.model"
                  :rules="prop.rules" v-model="model[prop.model]" :disabled="prop.disabled" :readonly="prop.readonly !== undefined ? prop.readonly : false " :rows="prop.rows" dense  @change="prop.change ? prop.change() : ''"></v-textarea>
              </template>
              <template v-else-if="prop.type === formType.CHECKBOX">
                <v-checkbox :hide-details="prop.hideDetails" :label="prop.label" :id="prop.model" color="primary" :rules="prop.rules" v-model="model[prop.model]" @change="prop.click ? prop.click(prop.model): ''" :disabled="prop.disabled"> </v-checkbox>
              </template>
              <template v-else-if="prop.type === formType.AUTO_COMPLETE">
                <v-autocomplete outlined hide-details :items="prop.items" :id="prop.model" :disabled="prop.disabled" dense
                  :rules="prop.rules" :item-text="prop.select_text" :multiple="prop.multiple" autocomplete="off"
                  @click:append="prop.click(prop.term)"
                  :item-value="prop.select_value" :label="prop.label"
                  :ref="prop.term? '' + prop.term : ''"
                  :append-icon="prop.is_list ? 'add_circle' : ''"
                  clearable
                  @change="prop.change ? prop.change() : ''"
                  v-model="model[prop.model]">
                  <template v-slot:selection="{ item, index }" v-if="prop.multiple">
                    <span v-if="index === 0">{{ item[prop.select_text] }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text caption"
                    >(+{{ model[prop.model].length - 1 }} {{ $t('message.common.others') }})</span>
                  </template>
                </v-autocomplete>
                <span v-if="prop.helpTextShow && prop.helpText">{{ prop.helpText }}</span>
              </template>
              <template v-else-if="prop.type === formType.SELECT">
                <v-select outlined hide-details :items="prop.items" :id="prop.model" :rules="prop.rules" :item-text="prop.select_text" dense :multiple="prop.multiple" :item-value="prop.select_value" :label="prop.label" :disabled="prop.disabled" v-model="model[prop.model]" @change="prop.change ? prop.change() : ''" @click="prop.click ? prop.click(): ''"></v-select>
              </template>
              <template v-else-if="prop.type === formType.PASSWORD">
                <v-text-field outlined hide-details :label="prop.label" :id="prop.model" :rules="prop.rules" :type="formType.PASSWORD" v-model="model[prop.model]" dense> </v-text-field>
              </template>
              <template v-else-if="prop.type === formType.COMBOBOX" class="pt-2">
                <div class="combo">
                  <v-combobox v-model="model[prop.model]" :items="prop.items"  hide-selected  :rules="prop.rules" :label="prop.label" multiple persistent-hint small-chips :id="prop.model" :disabled="prop.disabled" outlined dense hide-details></v-combobox>
                </div>
              </template>
              <template v-else-if="prop.type === formType.DATEPICKER">
                <v-menu :close-on-content-click="false" v-model="model[prop.menu]" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field outlined hide-details v-model="model[prop.model]" :rules="prop.rules" clearable :label="prop.label" prepend-inner-icon="mdi-calendar" dense
                    readonly v-on="on" :disabled="prop.disabled" @click:clear="$nextTick(() => model[prop.picker] = null)"></v-text-field>
                  </template>
                  <v-date-picker :color="prop.color" @input="model[prop.menu] = false; model[prop.model] = $formatter.formatDate(model[prop.picker], 'YYYY-MM-DD', 'DD.MM.YYYY')" :min="model[prop.min]" :max="model[prop.max]" v-model="model[prop.picker]" first-day-of-week='1'
                    :disabled="prop.disabled" :weekday-format="getDayFormat"></v-date-picker><!-- :locale="getLocale"  -->
                </v-menu>
              </template>
              <template v-else-if="prop.type === formType.QUICKADD">
                <v-btn fab small outline color="primary" :id="prop.type" @click="$router.push(prop.to)" :disabled="prop.disabled">
                  <v-icon color="primary">perm_identity</v-icon>
                </v-btn>
              </template>
              <template v-else-if="prop.type === formType.TIMEPICKER">
                <v-menu :close-on-content-click="false" :ref="prop.menu" v-model="model[prop.menu]" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field  outlined hide-details slot="activator" :label="prop.label" :rules="prop.rules" dense v-model="model[prop.model]" readonly v-on="on" prepend-inner-icon="mdi-clock-outline" clearable :disabled="prop.disabled"></v-text-field>
                  </template>
                  <v-time-picker color="primary" v-model="model[prop.model]" :min="setMin(model[prop.min])" :max="model[prop.max]" @click:minute="$refs[prop.menu][0].save(model[prop.model])" :allowed-minutes="allowedStep" format="24hr" :disabled="prop.disabled"></v-time-picker>
                </v-menu>
              </template>
              <template v-else-if="prop.type === formType.DATETIMEPICKER">
                <slot :name="prop.slot"></slot>
              </template>
              <template v-else-if="prop.type === formType.FILES">
                <input v-if="isIos" type="file" :id="prop.id ? prop.id : ''"  prepend-inner-icon="mdi-paperclip" @change="handleFileUpload($event, prop)" prepend-icon="" :accept="prop.accept" :hide-details="prop.hideDetails !== undefined ? prop.hideDetails : true" :disabled="prop.disabled" outlined :rules="prop.rules" :label="prop.label" :multiple="prop.multiple" dense />
                <v-file-input v-else prepend-inner-icon="mdi-paperclip" @change="prop.change ? prop.change() : ''" prepend-icon="" :accept="prop.accept" :hide-details="prop.hideDetails !== undefined ? prop.hideDetails : true" :disabled="prop.disabled" outlined v-model="model[prop.model]" :rules="prop.rules" :label="prop.label" :multiple="prop.multiple" dense></v-file-input>
                <slot :name="prop.slot"></slot>
              </template>
              <template v-else-if="prop.type === formType.COLORPICKER">
                <v-menu bottom :close-on-content-click="false" offset-y v-model="model[prop.menu]">
                  <template v-slot:activator="{ on }">
                    <v-text-field :label="prop.label" v-model="model[prop.model]" outlined hide-details class="ma-0 pa-0" readonly slot="activator" v-on="on" :disabled="prop.disabled" dense></v-text-field>
                  </template>
                  <v-color-picker v-if="[prop.menu]" v-model="model[prop.model]" bottom flat :disabled="prop.disabled" />
                </v-menu>
              </template>
              <template v-else-if="prop.type === formType.RADIO && prop.is_show">
                <v-radio-group v-model="model[prop.model]" row @change="prop.click ? prop.click(prop.model): ''">
                  <template v-for="items in prop.items">
                    <v-radio :label="items.text" :value="items.value" :key="items.value"></v-radio>
                  </template>
                </v-radio-group>
              </template>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
      <v-card-text class="pa-0">
        <slot name="extraDetails"></slot>
      </v-card-text>
      <v-divider v-if="references.buttons.length > 0"></v-divider>
      <v-card-actions v-if="references.buttons">
        <v-spacer></v-spacer>
        <v-layout justify-center>
          <v-flex>
            <template v-for="(button, index) in references.buttons">
              <v-btn :key="index" :loading="button.loading" :id="button.name" v-if="button.is_show" @click="button.click" :color="button.color" :disabled="button.disabled" class="mr-2"> {{ button.label }} </v-btn>
            </template>
          </v-flex>
        </v-layout>
      </v-card-actions>
      <slot name="button" v-else></slot>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: {
    references: {
      type: Object
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      isIos: false
    }
  },
  computed: {
    ...mapGetters(['formType']),
    getLocale () {
      return this.$i18n.locale === 'no' ? 'no-NO' : 'en-US'
    }
  },
  created () {
    window.addEventListener('resize', this.checkDeviceName)
  },
  destroyed () {
    window.addEventListener('resize', this.checkDeviceName)
  },
  mounted () {
    const userAgent = window.navigator.userAgent.toLowerCase() || navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || navigator.platform.toLowerCase()
    this.isIos = (/iphone|ipad|ipod/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document))
  },
  methods: {
    allowedStep: m => m % 5 === 0,
    getDayFormat (date) {
      const lang = this.$i18n.locale === 'no' ? 'nb' : 'en'
      return moment(date).locale(lang).format('dd')
      // return dayString.charAt(0).toUpperCase() + dayString.slice(1)
    },
    setMin (time) {
      if (time) time = moment(time, 'HH:mm').add(5, 'minutes').format('HH:mm')
      return time
    },
    handleFileUpload (event, prop) {
      this.model[prop.model] = event.target.files
      if (prop.change) prop.change()
    },
    checkDeviceName (e) {
      const userAgent = window.navigator.userAgent.toLowerCase() || navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || navigator.platform.toLowerCase()
      this.isIos = (/iphone|ipad|ipod/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document))
    }
  }
}
</script>
<style scoped>
.container.grid-list-xl .layout .flex {
  padding: 7px !important;
}
</style>
